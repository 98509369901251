/* Slideshow container */
.slideshow-container {
    min-width: 340px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
.display_text{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: baseline;
  flex-direction: column;
  transition: 2s ease;
}
.dot-container {
    text-align: center;
    padding: 20px;
    background:transparent;
    display:flex;
    height: 10%;
    align-items: center;
    justify-content: center;
  }
  .display_device{
      height: 90%;
      width: 100;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 8px;
    background-color:grey;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  /* Add a background color to the active dot/circle */
  .active, .dot:hover {
    background-color: rgba(250, 250, 250, 0.5);
  }
.device_image{
    width: 40%;
  
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

  }
