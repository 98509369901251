/* Your CSS file */
.wrapper{ 

  border-radius: 10px;
  min-width: 90vw !important;
}
.container_wrapper{
  min-width: 90vw;

}
.shop_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 0.5rem; 
  justify-content: center;
  background-color: #fff; 
  color: #2d3748;
  width: fit-content;
  justify-content: center;
}
.wrapper .div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wrapper .div ul{
  background-color: transparent;
}
.wrapper .div ul li button{background-color: transparent;}
.menu_child {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-right: 1.25rem; /* Adjust the right padding as needed (5 multiplied by 0.25rem) */
  padding-top: 0.5rem; /* Adjust the top padding as needed (3 multiplied by 0.25rem) */
  padding-bottom: 0.5rem; /* Adjust the bottom padding as needed (3 multiplied by 0.25rem) */
  gap: 0.5rem; /* Adjust the space between items as needed (2 multiplied by 0.25rem) */
  color: #4a5568; /* Use your desired text color */
}
.menu_child button{
  font-size: 1rem;
    background-color: white;
    width: 90px;
}
.heading {
  text-align: center;
  font-size: 1.5rem; /* Adjust the font size to match text-2xl */
  font-weight: bold;
  color: #2d3748; /* Use your desired text color */
}
/* Your CSS file */

.container {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.8rem;
  padding: 1rem;
  background-color: rgb(255, 242, 230);
  border-radius: 15px;
  min-height: 380px;
  width: 90vw;
  justify-items: center;
}

@media (min-width: 500px) {
  .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 880px) {
  .container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 80vw;
  }
}

.container li {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    width: 250px;
    height: 100%;
    transition-duration: .3s;
    overflow: hidden;
    background-color: transparent;
    cursor: pointer;
    min-height: 200px;
    margin-left:20px;
}

.container li .card-content {
    height: 100%;
    background-size: cover;
    background-position: center top;
}


/* news letter section */
.search_letter{
  background-color:transparent;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center ;
  flex-direction: column;
}
.search_letter 		
.input_container{
  width: 100%;
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_letter .input_container input{
  outline: none;
  padding-left: 10px;
  border-radius: 5px;
  border: none;
  width: 40%;
  min-width: 190px;
  height: 35px;
}
.search_letter .input_container button{
  width: 10%;
  height: 35px;
  background-color:#053674;
  color: white;
  font-size: 20px;
  min-width: 100px;
  padding: 5px;
  margin: 5px;
  border: none;
  border-radius: 5px;
}
.search_letter h1{
  color: #053674;
  font-size: 15px;
  font-weight: normal;

}
.news_letter p{
  margin: 10px;
}