.promotional-product-banner {
    padding: 50px 20px; /* Adjusted padding */
    background-color: #dcdcdc;
    border-radius: 15px;
    position: relative;
    height: 250px; /* Adjusted height */
    line-height: 1.2; /* Adjusted line-height */
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .promotional-product-banner .beats-solo {
    font-size: 16px; /* Adjusted font size */
  }
  
  .promotional-product-banner button {
    border-radius: 10px; /* Adjusted border-radius */
    padding: 8px 12px; /* Adjusted padding */
    background-color: #402df0;
    color: white;
    border: none;
    margin-top: 20px;
    font-size: 14px; /* Adjusted font size */
    font-weight: 500;
    cursor: pointer;
    z-index: 10000 !important;
  }
  
  .promotional-product-banner h3 {
    font-size: 2rem; /* Adjusted font size */
    margin-top: 4px;
  }
  
  .promotional-product-banner h1 {
    color: white;
    font-size: 3rem; /* Adjusted font size */
    text-transform: uppercase;
  }
  
  .promotional-product-image {
    position: absolute;
    top: 0%;
    right: 20%;
    width: 250px; /* Adjusted width */
    height: 250px; /* Adjusted height */
  }
  
  .promotional-product-desc {
    position: absolute;
    right: 10%;
    bottom: 5%;
    width: 200px; /* Adjusted width */
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    color: #324d67;
  }
  
  .promotional-product-desc p {
    color: #5f5f5f;
    font-weight: 100;
    text-align: end;
  }
  
  .promotional-product-desc h5 {
    margin-bottom: 8px; /* Adjusted margin */
    font-weight: 600; /* Adjusted font weight */
    font-size: 14px; /* Adjusted font size */
  }
  
  @media screen and (max-width: 800px) {
    .promotional-product-banner {
      height: 300px; /* Adjusted height */
    }
  
    .promotional-product-image {
      width: 70%;
      height: 56%;
      top: -2%;
      right: -6%;
    }
  
    .promotional-product-banner h1 {
      font-size: 1.5rem; /* Adjusted font size */
    }
  
    .promotional-product-banner h3 {
      font-size: 1.5rem; /* Adjusted font size */
    }
  
    .promotional-product-banner button {
      margin-top: 30px;
      z-index: 10000;
    }
  
    .promotional-product-desc {
      bottom: 30px; /* Adjusted bottom margin */
      width: 150px; /* Adjusted width */
    }
  }
  