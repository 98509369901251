.body {
    background-color: black;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;

}

body{
  font-family: 'Open Sans', sans-serif;
}
*{
  font-family: 'Open Sans', sans-serif;
}
