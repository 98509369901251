:root {
    --primary: rgb(77, 38, 0);
    --background: rgb(255, 242, 230);
    --navbar-height: 62px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins;
    color: var(--primary)f;
}
.Heading{
    font-size: 2rem;
}
.menu__wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    color: var(--primary);
}

.menu__bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    z-index: 2;
    padding: 0 16px;
    gap: 32px;
    background-color: var(--background);
    height: 60px;
    opacity: 0.95;
    color: var(--primary);
}

.menu-icon {
    cursor: pointer;
    display: flex;
    height: 32px;
    width: 32px;
    color: var(--primary);
}

.navigation {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    gap: 24px;
    padding: 0px;
    background-color: var(--background);
}

.navigation li {
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 1.25rem;
    color: var(--primary);
}

.navigation li .a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 500;
}

.navigation li .a:before {
    content: '';
    background: var(--primary);
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    color: var(--primary);
    width: 0;
    height: 2px;
    transition: all 0.2s ease-in-out;
}

.navigation li .a:hover {
    background-position: 0;
}

.navigation li .a:hover{
    color: var(--primary);
    width: 100%;
}
.logo{
    min-width: 200px;
}

@media (min-width: 600px) {
    .menu-icon {
        display: none;
    }
    
}

@media (max-width: 600px) {
    .navigation {
        display: none;
    }

    .menu-icon {
        display: block;
    }
    .Heading{
        font-size: 1.5rem;
    }
    .navigation--mobile {
        top: var(--navbar-height);
        position: fixed;
        right: 0px;
        display: flex;
        padding: 60px 50px;
        min-height: 100%;
        background-color: var(--background);
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
        opacity: 0.95;
        animation: fadein 0.3s forwards;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
            width: 0;
            height: 0;
        }

        100% {
            opacity: 1;
            width: 100%;
            height: calc(100vh - var(--navbar-height));
        }
    }

    .navigation--mobile--fadeout {
        animation: fadeout 300ms forwards;
    }

    @keyframes fadeout {
        0% {
            opacity: 1;
            width: 100%;
            height: calc(100vh - var(--navbar-height));
        }

        100% {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
}
