::-webkit-scrollbar {
    width: 10px;
  }
*{
    box-sizing: border-box;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}
.app {
    height: inherit;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color:rgb(255, 242, 230);
    overflow-y: scroll;
}
Link {
    height: inherit;
    width: inherit;
    text-decoration: none;
    border: none;
}

.single_product{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
button{
    border: none;
}