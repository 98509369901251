.bottom_navbar{
    height: inherit;
    width: 100vw;
    background-color:#331900;
    display: flex;
    overflow: hidden;
    height: inherit;
    padding: 0 10px;
    box-sizing: border-box;
}
.menue_contents{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 1.4rem;
}
/* menue container styles */
.menue_contents .menue_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.menue_container ul{
    list-style: none;
}
.menue_container ul li{
    line-height: 26px;
    color: #545559;
}
.menue_container h3{
    color: #053674;
    line-height: 30px;
}
.shopsmart_rights{
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
}
@media only screen and (min-width: 800px) {
    .bottom_navbar{
        height: inherit;
        width: 100vw;
        background-color:#331900;
        display: flex;
        overflow: hidden;
        height: inherit;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .menue_contents{
        display: flex;
        flex-direction: column;
    }   
    /* menue container styles */
    .menue_contents .menue_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .menue_container ul{
        list-style: none;
        display: flex;
        justify-content: space-evenly;
    }
    .menue_container ul li{
        line-height: 26px;
        color: white;
        margin: 10px;
    }
    .menue_container h3{
        color: #ff8c1a;
        line-height: 30px;
    }
    .shopsmart_rights{
        margin-top: 15px;
        margin-bottom: 10px;
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }       
}