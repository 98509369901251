/* hero section */
.hero{
    width: 100vw;
    height: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hero_img{
    overflow: hidden;
    height: 28vh;
    width:100vw;
    display: flex;
    flex-direction: column;
    max-height: 400px;
}
/* hero shop area  */
.hero_shop_name{
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.hero_shop_name button{
    width: 180px;
height: 36px;
background: white;
border-radius: 10px;
}
.hero_shop_name h1{
    font-style: normal;
    font-size: 2rem;
    line-height: 30px;
}
.hero_shop_name p{
    text-align: center;
    padding-bottom: 0px;
    width: 100%;
    text-justify: center;
    font-size: 20px;}
/* slider for product display */
.hero_shop_slidder{
    width: 100%;
    height: 100%;
    display: flex;
}



/* brands section */
/* news letter section */
.news_letter{
    background-color:#331900;
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center ;
    margin: 0;
    flex-direction: column;
}
.news_letter 		
.input_container{
    width: 100%;
    height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}
.news_letter .input_container input{
    outline: none;
    padding-left: 10px;
    border-radius: 5px;
    border: none;
    width: 40%;
    min-width: 190px;
    height: 35px;
}
.news_letter .input_container button{
    width: 10%;
    height: 35px;
    background-color: #053674;
    color: white;
    font-size: 20px;
    min-width: 100px;
    padding: 5px;
    margin: 5px;
    border: none;
    border-radius: 5px;
}
.news_letter h1{
    color: #053674;
    font-size: 15px;
    font-weight: normal;

}
.news_letter p{
    margin: 10px;
    color: #ff8c1a;
}

/* brands this part contains the logos and the names */
.brands_{
    height: fit-content;
    display: flex;
    min-width: 80%;
    justify-content: center;
    padding: 10px;  
    width: 90%;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    background-color: rgb(255, 242, 230);
    border-radius: 10px;
    margin: 10px;
}
.fav_brands{
    width: 40%;
}
.logos{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
}
.fav_brands h1{
    color: #331a00;
    font-size: 1.2rem;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-family: 'Assistant', sans-serif;
}
.circle_brand{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    margin: 2.5px 5px;
    align-items: center;
    justify-content: center;
    
}
@media (min-width:500px) {
    .news_letter{
        height: 40vh;
    }
    .hero_img{
        overflow: hidden;
        height: 70vh;
        width:100vw;
        display: flex;
        flex-direction: row;
    }
    .hero_shop_name{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .hero_shop_name button{
        width: 180px;
    height: 36px;
    background: #331900;
    border-radius: 10px;
    }
    .hero_shop_name h1{
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
    }
    .hero_shop_name p{
        padding-top: 0px;
        padding-bottom: 0px;
        text-align: center;
        text-justify: center;
        font-size: 25px;
    }
    /* slider for product display */
    .hero_shop_slidder{
        width: 60%;
        height: 100%;
        display: flex;
    }
    .news_letter{   
        height: 20vh;
    }
}

  
        
  