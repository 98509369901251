.user_not_found, .page_not_found{
    width: 100vw;
    background-color: rgb(221, 219, 219);
    height: inherit;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}


.page1, .user1{
    width: 60%;
    min-width: 310px;
    height: 420px;
    padding: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    flex-direction: column;
}
.page1 li button {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 5px;

    background-color:grey;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  .page1 button{
    background-color: rgba(233, 181, 40, 0.75);
    color: black;
    height: 40px;
    font-size: 20px;
    font-weight: 15 px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
  }
.page1 h4{
    font-size: 20px;
    font-weight: normal;
    }