/* Style the form container */
#myForm {
    max-width: 90vw;
    min-width: 70vw;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color:#ff8c1a;
    background-color:#331900;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  #myForm input, #myForm textarea{
    color: black;
    outline: none;
  }
  /* Style form sections */
  .formSection {
    margin-bottom: 15px;
  }
  
  /* Style labels */
  .formLabel {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Style input fields */
  .formInput {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style radio buttons and checkboxes */
  .formRadio,
  .formCheckbox {
    margin-right: 5px;
  }
  
  /* Style the submit button */
  .formSubmit {
    background-color: #ff8c1a;
    color: #331900;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Style the submit button on hover */
  .formSubmit:hover {
    background-color: #45a049;
  }
  
  /* Style the form title */
  .formTitle {
    margin-bottom: 10px;
  }
  
  /* Optional: Add some spacing between the message paragraph and the input */
  .formMessage {
    margin-bottom: 10px;
  }

  #message {
    height: 200px;
    resize: vertical; /* Allow vertical resizing */
  }
  
  /* Ensure that the label is positioned above the textarea */
  #messageLabel {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }