.review_container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    max-width: 600px;
    min-width: 300px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-container {
    display: flex;
    align-items: center;
}

.profile-image {
    border-radius: 50%;
    height: 56px;
    width: 56px;
    min-width: 56px;
    min-height: 56px;
}

.text-container {
    flex-grow: 1;
    max-width: 70%;
}

.bold-text {
    font-weight: bold;
    color: white;
}

.small-text {
    font-size: 14px;
    color:white;
    margin-top: 5px;
}

.right-container {
    font-size: 12px;
    color: white;
}

@media (min-width: 768px) {
    .right-container {
        font-size: 14px;
    }
}
