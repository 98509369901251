.services_slides_container{
  height: 500px;
  background-color:#331a00;
  width: 80vw;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.service_name{
  color: gray;
  padding: 0 !important;
  margin: 0 !important;
}
.service_image{
  min-width: 300px;
}
.service_image{
  min-width: 300px;
  height: 80%;
  width: 80vw;
  object-fit: cover;
}
.service_button {
  background-color: transparent;
  border: 2px solid #007bff; /* Blue border */
  border-radius: 20px; /* Rounded border */
  color: #007bff; /* Blue text color */
  padding: 10px 20px; /* Adjust padding as needed */
  cursor: pointer;
  transition: color 0.3s, border-color 0.3s; /* Transition effect on color and border-color */
}

.service_button:hover {
  color: #fff; /* White text color on hover */
  border-color: #fff; /* White border color on hover */
}
.service_image, .service_name {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; 
  opacity: 0; 
    transform: translateY(-20px);
}
.service_image.show, .service_name.show {
  opacity: 1;
  transform: translateY(0);
}


@media only screen and (max-width: 500px) {
  .services_slides_container{
    height: 400px;
    background-color:#331a00;
    width: 90vw;
  }
  .service_name{
    color: rgba(250, 250, 250, 0.6);
  }
  .service_image{
    min-width: 300px;
    height: 300px;
    width: 100%;
  }
}