*{
    margin: 0;
    padding: 0;
}
.action-buttons {
    position: relative;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    scroll-snap-align: start;
    width: 100%;
    flex-wrap: wrap;
}
.action-button_right {
    border-radius: 16px;
    height: 32px;
    gap: 4px;
    cursor: pointer;
    display: flex;
    padding: 0 1px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    align-items: center;
    text-decoration: none;
    background-color: var(--secondary);   
    min-width: 32px;
    justify-content: center;
    margin: 5px;
}
.action-button_left {
    border-radius: 16px;
    height: 32px;
    gap: 4px;
    left: 200px;
    cursor: pointer;
    display: flex;
    padding: 0 1px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    align-items: center;
    text-decoration: none;
    background-color: var(--secondary);   
    min-width: 32px;
    justify-content: center;
    margin: 5px;
}


.action-button--primary:active {
    background-color: var(--accent-active);
}

.action-button:active {
    background-color: var(--secondary-active);
}

.action-button--horizontal-scroll {
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    background-color: var(--accent);
}

@media screen and (max-width: 768px) {
    .action-button--horizontal-scroll {
        display: none;
    }
}

.action-button--horizontal-scroll:hover {
    background-color: var(--accent-hover);
}

.action-button--horizontal-scroll:active {
    background-color: var(--accent-active);
}

.action-button--horizontal-scroll:disabled {
    cursor: not-allowed;
    background-color: var(--secondary);
}

.action-button--horizontal-scroll:disabled:hover {
    background-color: var(--secondary);
}

.action-button--horizontal-scroll:disabled:active {
    background-color: var(--secondary);
}

#action-button--previous {
    margin-left: auto;
    transform: rotate(-90deg);
}

#action-button--next {
    transform: rotate(90deg);
}

.action-button:hover {
    background-color: var(--secondary-hover);
}

.action-button--primary {
    background-color: var(--accent);
}

.action-button--primary:hover {
    background-color: var(--accent-hover);
}

.action-button--primary:active {
    background-color: var(--accent-active);
}

#bank-accounts {
    background: #331900;
    display: flex;
    gap: 16px;
    padding: 16px 0px;
    width: 100vw;
    flex-direction: row;
    overflow: auto;
}


.bank-account {
    appearance: none;
    text-decoration: none;
    position: relative;
    padding: 16px;
    border-radius: 16px;
    height: 208px;
    transition: all 0.2s ease-in-out;
    width: 208px;
    display: flex;
    flex: 0 0 208px;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    scroll-snap-align: start;
}

.bank-account h2 {
    color: var(--content-primary);
    font-size: 1.375rem;
    letter-spacing: normal;
    overflow-wrap: normal;
    white-space: nowrap;
    font-weight: 700;
    line-height: 1.25rem;
}

.bank-account .currency {
    color: var(--content-primary);
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: -.006em;
    line-height: 133%;
}

.bank-account:hover {
    background: var(--secondary-hover);
}

.container_bank {
    width: 100%;
    min-width: 300px;

}
/* Global styles for common button properties */
.review_button_parent{
    width: 100vw;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #331900;
}
.client-reviews-button {
    border: 2px solid #fff; /* Blue border */
    border-radius: 20px;
    color: #fff; /* Blue text color */
    padding: 10px 20px; 
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
    transition: color 0.3s, border-color 0.3s;
}

.client-reviews-button:hover {
    color: gray; /* White text color on hover */
    border-color: gray; /* White border color on hover */
}
