.hero-banner-container {
  background-color: #331900;
  border-radius: 15px;
  position: relative;
  height: 350px;
  line-height: 0.9;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 300px;
  overflow: hidden;
  color: white;
}

.hero-banner-container .beats-solo {
  font-size: 16px;
}
.banner-wrapper{
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
}

.hero-banner-container button {
  border-radius: 10px;
  padding: 7px 12px;
  background-color: #f02d34;
  color: white;
  border: none;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  z-index: 10000 !important;
}

.hero-banner-container h3 {
  font-size: 2rem;
  margin-top: 4px;
}

.hero-banner-container h1 {
  color: white;
  font-size: 10em;
  margin-left: -20px;
  text-transform: uppercase;
}

.hero-banner-image {
  position: absolute;
  top: 0%;
  right: 20%;
  width: 450px;
  height: 450px;
}

.desc {
  position: absolute;
  right: 10%;
  bottom: 5%;
  width: 300px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  color: #324d67;
}

.desc p {
  color: #5f5f5f;
  font-weight: 100;
  text-align: end;
}

.desc h5 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .hero-banner-container {
    height: 200px;
  }
  .hero-banner-image {
    width: 77%;
    height: 62%;
    top: -2%;
    right: -6%;
  }
  .hero-banner-container h1 {
    font-size: 50px;
  }
  .hero-banner-container h3 {
    font-size: 20px;
  }
  .hero-banner-container button {
    margin-top: 90px;
    z-index: 10000;
  }
  .desc {
    bottom: 60px;
  }
}


