.card_item_cont {
  border-radius: 0.75rem;
  background-color:white;
  padding-top: 0;
  padding-bottom: 0.75rem;
  transition-property: box-shadow, transform;
  height: 100%;
  width: 100%;
}
.child_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 0;
  color: #fff;
  transition-duration: 0.1s;
  height: fit-content;
}
.child_div img{
  height: 230px;
width: 100%;
  object-fit: cover;
  object-position: center;
  
}
.prod_desc {
  margin-top: 0rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
    }
.prod_desc .prod {
  color: #6b7280;
}
.prod_dec .p{
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #cbd5e0;
}
.price {
margin-top: 0.75rem; /* Adjust the top margin as needed (3 multiplied by 0.25rem) */
display: flex;
align-items: flex-end;
justify-content: space-between;
}
.price p {
font-size: 1.125rem; /* Adjust the font size to match text-lg */
font-weight: bold;
color: #3b82f6; /* Use your desired text color (corresponding to text-blue-500) */
}

.shopping_cart {
display: flex;
align-items: center;
gap: 0.375rem; /* Adjust the space between items as needed (1.5 multiplied by 0.25rem) */
border-radius: 0.75rem;
background-color: #ff8000; /* Use your desired background color (corresponding to bg-blue-500) */
padding-left: 1rem; /* Adjust the left padding as needed (4 multiplied by 0.25rem) */
padding-right: 1rem; /* Adjust the right padding as needed (4 multiplied by 0.25rem) */
padding-top: 0.375rem; /* Adjust the top padding as needed (1.5 multiplied by 0.25rem) */
padding-bottom: 0.375rem; /* Adjust the bottom padding as needed (1.5 multiplied by 0.25rem) */
color: #fff; /* White text color */
transition-duration: 0.1s;

&:hover {
  background-color: #2563eb; /* Use your desired darker blue color on hover (corresponding to hover:bg-blue-600) */
}
}
.cart_btn{
background-color: transparent;
border: none;
color: white;
font-size: 0.75rem;
font-weight: bold;
}
.rating {
position:absolute;
top: 160px;
right: 4px;
display: inline-flex;
align-items: center;
border-radius: 0.375rem; /* Equivalent to rounded-lg in Tailwind */
background-color: #fff; /* Equivalent to bg-white in Tailwind */
padding: 0.2rem; /* Equivalent to p-2 in Tailwind */
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-md in Tailwind */
}
.star {
height: 1.25rem; /* Equivalent to h-5 in Tailwind */
width: 1.25rem; /* Equivalent to w-5 in Tailwind */
fill: #ff8c1a; /* Equivalent to text-yellow-400 in Tailwind */
}
.rating_count {
margin-left: 0.25rem; /* Equivalent to ml-1 in Tailwind */
font-size: 0.875rem; /* Equivalent to text-sm in Tailwind */
color: #718096; /* Equivalent to text-slate-400 in Tailwind */
}
